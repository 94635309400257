import React from 'react';

export default function ExpirationEventMetadataTable(props)
{
    return (
        <>
            <table data-role="subdetailheader">
                <thead>
                    <tr>
                        <th>Status:</th>
                        <th>Created By:</th>
                        <th>Created Date:</th>
                        <th>Last Updated By:</th>
                        <th>Last Updated Date:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.statusLabel}</td>
                        <td>{props.createdByUserName}</td>
                        <td>{props.createdDateDisplay}</td>
                        <td>{props.lastUpdatedUserName}</td>
                        <td>{props.lastUpdatedDisplay}</td>
                    </tr>
                </tbody>
            </table>
            <hr className="abcblue"></hr>
        </>
    );
}