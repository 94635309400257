import React from 'react';

export default function DisplayContext (props)
{
    let { contextType, masthead, message, details } = props;
    const mainclassname = `messagenotification ${contextType}`;
    let iconcss = '';
    masthead = masthead || props.contextType?.toUpperCase() || '';

    switch (props.contextType)
    {
        case 'error':
            iconcss = 'bi bi-x-circle-fill';
            break;
        case 'success':
            iconcss = 'bi bi-check-circle-fill';
            break;
        case 'warning':
            iconcss = 'bi bi-exclamation-circle-fill';
            break;
        default:
            iconcss = 'bi bi-info-circle-fill';
            break;
    }

    return (
        <div className={mainclassname}>
            <div data-role="iconcontainer">
                <table>
                    <tbody>
                        <tr>
                            <td className="iconcell">
                                <span className={iconcss}>
                                    <span>{contextType}</span>
                                </span>
                            </td>
                            <td className="contentcell">
                                <div data-role="contentcontainer">
                                    {
                                        masthead.length > 0 &&
                                        <div data-role="masthead">{masthead}</div>
                                    }
                                    {
                                        message.length > 0 &&
                                        <div data-role="standardmessage">{message}</div>
                                    }
                                    {
                                      details.length > 0 &&
                                        <div data-role="messagelistcontainer">                                            
                                            <ul className="detailslist">
                                                {
                                                    (details.map((item, key) => { return (<li key={key}>{item}</li>) }))
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>    
    );
}