import { React } from 'react';
import DisplayContext from './DisplayContext';

import './displayareamessenger.css';

export function createDisplayAreaMessengerContext()
{
    return {
        'error': {
            masthead: '',
            message: '',
            details: []
        },
        'success': {
            masthead: '',
            message: '',
            details: []
        },
        'warning': {
            masthead: '',
            message: '',
            details: []
        },
        'information': {
            masthead: '',
            message: '',
            details: []
        }
    };
}

export function DisplayAreaMessenger (props)
{
    const { error, success, warning, information } = props.context;

    const checkDataIntegrity = data => {
        const validVariableType = !!(typeof(data) === 'object' && data != null);
		
		if (!validVariableType)
			return false;
		
        const validMessage = !!(data.hasOwnProperty('message') && data.message.length > 0);
        const validDetails = !!(data.hasOwnProperty('details') && data.details.length > 0);
        return !!(validVariableType && (validMessage || validDetails));
    };

	return (
        <div data-role="messagecontainer">
            {
                checkDataIntegrity(error) &&
                <DisplayContext contextType="error" {...error} />
            }
            {
                checkDataIntegrity(success) &&
                <DisplayContext contextType="success" {...success} />
            }
            {
                checkDataIntegrity(warning) &&
                <DisplayContext contextType="warning" {...warning} />
            }
            {
                checkDataIntegrity(information) &&
                <DisplayContext contextType="information" {...information} />
            }
        </div>
    );
}