import React from 'react';
import CertificateTypeDisplay from './CertificateTypeDisplay';

export default function CertificateTypeDisplayList (props)
{
    if (!props || !Array.isArray(props.items))
        return (<></>);

    return (
        <>
            {
                props.items.map((item, key) => {
                    return (<CertificateTypeDisplay key={key} {...item} />)
                })
            }
        </>
    );
}