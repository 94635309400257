import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import createUserSessionActivity from '../utilities/usersessiontracking';
import { createHttpClient } from '../utilities/httpclient';
import LocalStorageController from '../utilities/lsc';

export function Logout()
{
    const navigate = useNavigate();

    const executeLogout = () => {
        createUserSessionActivity(window.location.pathname);
        const httpclient = createHttpClient();
        httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/systemaccess/logout`).then(() => {
            let lsc = new LocalStorageController();
            lsc.remove(process.env.REACT_APP_AUTH_TOKEN);
            navigate('/', { replace: true });
        });
    };

    useEffect(() => executeLogout(), []);

    return (<></>);
}