import { React, useState, useEffect, useRef } from 'react';
import { Navbar, NavbarBrand, NavItem } from 'reactstrap';
import { useLocation, NavLink } from 'react-router-dom';
import { createHttpClient } from '../utilities/httpclient';
import LoginHelp from './modals/LoginHelp';
import '../styles/header.css';

export function Header() {
    let [userKey, setUserKey] = useState(-1);
    let [userName, setUserName] = useState('');

    const location = useLocation();

    const usingViewOtherThanSelfService = !location.pathname.includes('selfservice');

    const cLoginHelpRef = useRef(null);

    const onLoginInfoClick = e => cLoginHelpRef.current.showLoginHelp(e);

    useEffect(() => {
        if (usingViewOtherThanSelfService) //not needed for self-service view
        {
            const httpclient = createHttpClient();
            httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/systemaccess/user`).then(response => {
                setUserKey(+(response?.data?.key));
                setUserName(response?.data?.name);
            })
            .catch(e => console.table(e));
        }
    }, []);

    const settingsURL = `/accountsettings/${userKey}`;

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3" light>
                <NavbarBrand href="/main">my<strong>EDI</strong></NavbarBrand>
                <ul className="navbar-nav d-flex flex-grow">
                    <NavItem className="leftside">
                        {
                            usingViewOtherThanSelfService &&
                            <NavLink
                                to="/main"
                                className={(({ isActive }) => isActive ? "activelink" : "")}
                                >Home
                            </NavLink>
                        }
                    </NavItem>
                    <NavItem className="leftside">
                        {
                            usingViewOtherThanSelfService &&
                            <NavLink
                                to="/communitylookup"
                                className={(({ isActive }) => isActive ? "activelink" : "")}
                                >Certificate Lookup
                            </NavLink>
                        }
                    </NavItem>
                    <NavItem className="leftside">
                        <NavLink to="#" onClick={onLoginInfoClick}>Help</NavLink>
                    </NavItem>
                    <NavItem className="rightside">
                        {
                            usingViewOtherThanSelfService &&
                            <div className="white">
                                <NavLink title="Account Settings"
                                    style={{ 'display': 'inline-block' }}
                                    to={settingsURL}
                                    className={(({ isActive }) => isActive ? "activelink" : "")}
                                    >
                                    <span title="Account Settings" className="bi bi-person-circle"></span>
                                </NavLink>&nbsp;
                                Username: {userName}
                            </div>
                        }
                    </NavItem>
                    <NavItem className="rightside">
                        <NavLink to="/logout">Sign Out</NavLink>
                    </NavItem>
                </ul>
            </Navbar>
            <LoginHelp ref={cLoginHelpRef}></LoginHelp>
        </header>
    );
}