import { React, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createHttpClient, handleRemoteError } from '../utilities/httpclient';
import { AbdsButton, AbdsInput } from '@abds/react-bindings';
import createUserSessionActivity from '../utilities/usersessiontracking';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from './displayareamessenger/DisplayAreaMessenger';

import { useKeyPressEnter } from '../customhooks/useKeyPressEnter';

import '../styles/absgtable.css';
import '../styles/communitylookup.css';

export function CommunityLookup (props)
{
    const rName = useRef(null);
    const rAS2ID = useRef(null);
    const rSubmitButton = useRef(null);

    let [communityName, setCommunityName] = useState('');
    let [defaultRoutingID, setDefaultRoutingID] = useState('');
    let [certificateDetails, setCertificateDetails] = useState(null);
    let [messageContext, setMessageContext] = useState(createDisplayAreaMessengerContext());

    const navigate = useNavigate();

    const onChangeCommunityName = e => setCommunityName(e.target.value);

    const onChangeDefaultRoutingID = e => setDefaultRoutingID(e.target.value);

    const onClickGetCertificateDetails = e => {
        e.preventDefault();
        const data = {
            'CommunityNameQuery': communityName,
            'DefaultRoutingIDQuery': defaultRoutingID
        };
        createUserSessionActivity(window.location.pathname, 'onClickGetCertificateDetails', data);
        getCertificateDetails(data);
    };

    const onClickGoToCertificateDetails = (event, detail) => {
        event.preventDefault();

        if (!!detail.expirationEventGUID)
        {
            navigate(`/certificatedetails/${detail.expirationEventGUID}`);
            return;
        }
        const communityID = +(detail.communityID || 0);
        const certificateID = +(detail.certificateID || 0);
        navigate(`/certificatedetails/${communityID}/${certificateID}`);
    };

    const onClickDownloadCertificate = (event, detail, type) => {
        event.preventDefault();
        setMessageContext({});
        const criteria = {
            'CommunityID': +(detail.communityID || -1),
            'CertificateID': +(detail.certificateID || -1),
            'FileExtension': type
        };
        createUserSessionActivity(window.location.pathname, 'onClickDownloadCertificate', criteria);
        const httpclient = createHttpClient();
        httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/certificate/download`, criteria).then(response => {
            const isValidResponse = (r => {
                return !!r && !!r.data &&
                    r.data.hasOwnProperty('fileContents') &&
                    r.data.hasOwnProperty('contentType') &&
                    r.data.hasOwnProperty('fileDownloadName');
            })(response);

            if (!isValidResponse)
            {
                showErrorMessage('There was a problem fetching your requested certificate');
                return;
            }
            const blob = new Blob([response.data.fileContents], { 'type': response.data.contentType });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = response.data.fileDownloadName;
            link.click();
        })
        .catch(e => {
            if (!e)
                return;

            if (+(e?.response?.status) === 401)
            {
                navigate('/?error=1', {replace:true});
            }
            else if (!!e.response?.data || !!e.message)
            {
                showErrorMessage(e.response?.data || e.message);
            }
        });
    };

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const getCertificateDetails = criteria => {
        criteria = criteria || {
            'CommunityNameQuery': '',
            'DefaultRoutingIDQuery': ''
        };
        const httpclient = createHttpClient();
        httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/community/community`, criteria).then(response => {
            setCertificateDetails((response?.data) || []);
        })
        .catch(e => {
            if (!e)
                return;

            (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message));
        });
    };

    useKeyPressEnter(rSubmitButton);

    useEffect(() => {
        createUserSessionActivity(window.location.pathname);
    }, []);

    useEffect(() => {
        rAS2ID.current?.addEventListener('abdsInput', onChangeDefaultRoutingID);
        rName.current?.addEventListener('abdsInput', onChangeCommunityName);

        return () => {
            rAS2ID.current?.removeEventListener('abdsInput', onChangeDefaultRoutingID);
            rName.current?.removeEventListener('abdsInput', onChangeCommunityName);
        };
    }, [rAS2ID, rName]);

    return (
        <div>
            <h3>Certificate Management: Lookup</h3>

            <hr className="abcblue" />

            <DisplayAreaMessenger context={messageContext} />

            <h4>Lookup certificates</h4>

            <table className="communitysearchcontainer">
                <thead />
                <tbody>
                    <tr>
                        <td>
                            <div className="communitysearch">
                                <AbdsInput
                                    type="text"
                                    width="full"
                                    name="cname"
                                    helperText="Community/Partner Details"
                                    ref={rName}
                                    value={communityName}
                                    placeholder="Community/Partner Details">
                                    Community Name
                                </AbdsInput>
                            </div>
                        </td>
                        <td>
                            <div className="communitysearch">
                                <AbdsInput
                                    type="text"
                                    width="full"
                                    name="cas2id"
                                    helperText="Enter the Default Routing ID"
                                    ref={rAS2ID}
                                    value={defaultRoutingID}
                                    placeholder="0000000000">
                                    AS2 ID
                                </AbdsInput>
                            </div>
                        </td>
                        <td>
                            <AbdsButton ref={rSubmitButton} onClick={onClickGetCertificateDetails}>Get Certificate Details</AbdsButton>
                        </td>
                    </tr>
                </tbody>
            </table>
            {
                certificateDetails === null &&
                <></>
            }
            {
                certificateDetails?.length === 0 &&
                <>
                    <hr className="abcblue" />
                    <h3 className="center">No certificate data</h3>
                </>
            }
            {
                certificateDetails?.length > 0 &&
                <>
                    <hr className="abcblue" />

                    <h4>Certificate Details</h4>

                    <table className="absgtable auxiliary">
                        <thead>
                            <tr>
                                <th>Community Name:</th>
                                <th>Certificate Name:</th>
                                <th>Serial Number:</th>
                                <th>AS2 ID:</th>
                                <th>Begin Date:</th>
                                <th>End Date:</th>
                                <th className="centered">Downloads:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                certificateDetails.map(detail => {
                                    return (
                                        <tr key={detail.communityID.toString() + detail.certificateID.toString()}>
                                            <td>{detail.communityName}</td>
                                            <td><a href="#" onClick={event => onClickGoToCertificateDetails(event, detail)}>{detail.certificateName}</a></td>
                                            <td>{detail.serialNumber}</td>
                                            <td>{detail.aS2ID}</td>
                                            <td>{detail.beginDateDisplay}</td>
                                            <td>{detail.endDateDisplay}</td>
                                            <td className="centered">
                                                <a href="#" onClick={event => onClickDownloadCertificate(event, detail, 'CER')} download>.CER</a>&nbsp;|&nbsp;
                                                <a href="#" onClick={event => onClickDownloadCertificate(event, detail, 'P7B')} download>.P7B</a>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </>
            }
        </div>
    );
}