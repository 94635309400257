import axios from 'axios';
import LocalStorageController from './lsc';

function createHttpClient()
{
    const lsc = new LocalStorageController();
    const tkn = lsc?.getData(process.env.REACT_APP_AUTH_TOKEN);
    const settings =
    {
        headers: (tkn?.toString().length > 0)
            ? { Authorization: `Bearer ${tkn}` }
            : {}
    };
    settings.headers['Content-Type'] = 'application/json; charset=UTF-8';
    return axios?.create(settings);
}

function handleRemoteError (funcNavigate, ex, overridingMessage=null) //funcNavigate is the function created from useNavigate, which MUST be called within a component
{
    if (+(ex.response.status) === 401 || (!!ex && !!ex.response && !!ex.response.data && typeof(ex.response.data) === 'string'))
    {
        if (ex.response.data.toLowerCase().includes('timed out'))
        {
            const lsc = new LocalStorageController();
            const rand = Math.floor(Math.random() * 99999999);
            lsc.save(rand, overridingMessage ?? ex.response?.data ?? ex.message);
            funcNavigate(`/?error=${rand}`, { replace: true });
            return true;
        }
    }

    return false;
}

export { createHttpClient, handleRemoteError };