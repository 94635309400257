import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Main } from './components/Main';
import { CommunityLookup } from './components/CommunityLookup';
import { AccountSettings } from './components/AccountSettings';
import { CertificateManagementDetails } from './components/CertificateManagementDetails';
import { CertificateSelfService } from './components/CertificateSelfService';
import { ExpirationEventPartners } from './components/ExpirationEventPartners';

function App()
{
    return (
        <Layout>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path="/main" element={<Main />} />
                <Route path="/accountsettings/:userID" element={<AccountSettings />} />
                <Route path="/communitylookup" element={<CommunityLookup />} />
                <Route path="/certificatedetails/:expirationEventGUID" element={<CertificateManagementDetails />} />
                <Route path="/certificatedetails/:communityID/:certificateID" element={<CertificateManagementDetails />} />
                <Route path="/certificateselfservice" element={<CertificateSelfService />} />
                <Route path="/certificateselfservice/:userToken" element={<CertificateSelfService />} />
                <Route path="/expirationeventpartners/:expirationEventGUID" element={<ExpirationEventPartners /> } />
            </Routes>
        </Layout>
    );
}

export default App;