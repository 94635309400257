export default class LocalStorageController
{
	constructor()
	{
		this.storage = window.localStorage;
	}

	getData(key)
	{
		return JSON.parse(this.storage.getItem(key) ?? 'null');
	}

	clear()
	{
		this.storage.clear();
	}

	remove(key)
	{
		this.storage.removeItem(key);
	}

	save (key, data)
	{
		try
		{
			this.storage.setItem(key, JSON.stringify(data));
		}
		catch (ex)
		{
			if ([1014, 22, -2147024882].includes(+ex.code)) //purge if storage gets full
			{
				this.clear();
				this.save(key, data);
			}
		}
	}
}