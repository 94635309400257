import { React, forwardRef, useImperativeHandle } from 'react';
import { AbdsButton } from '@abds/react-bindings';
import { EMAIL_MYEDI_SUPPORT } from '../../utilities/constants';

import '../../styles/loginhelp.css';

/* anything defined in forwardRef-useImperativeHandle hooks is designed to be called from parent component only */
const LoginHelp = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        showLoginHelp(e) // <- this is called from parent
        {
            onClickOpenLoginHelp(e); // <- this is called within child (this) component
        }
    }));

    const MAILTO = `mailto:${EMAIL_MYEDI_SUPPORT}`;

    const onClickOpenLoginHelp = e => {
        e.preventDefault();
        document.getElementById('modalbackground').classList.add('visible');
        document.getElementById('asideloginhelp').style.display = 'block';
    };

    const onClickCloseLoginHelp = e => {
        e.preventDefault();
        document.getElementById('modalbackground').classList.remove('visible');
        document.getElementById('asideloginhelp').style.display = 'none';
    };

    return (
        <aside id="asideloginhelp">
            <h5><strong>Need help?</strong></h5>
            <p>Contact the myEDI Application Support Team for assistance at:</p>
            <p><a href={MAILTO} >{EMAIL_MYEDI_SUPPORT}</a></p>
            <div>
                <AbdsButton className="float-right" onClick={onClickCloseLoginHelp}>Close</AbdsButton>
            </div>
        </aside>
    );
});

export default LoginHelp;