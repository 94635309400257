const ENTERKEY = 13;

const REMEMBERME = 'CCSABRM';

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const REGEX_PHONE = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

const checkUserTokenFormat = subject => {
    if (!subject || subject === '00000000-0000-0000-0000-000000000000')
        return false;

    return !!(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(subject));
};

//controls Cencora visibilty - be sure to set to FALSE for AB
const USE_CENCORA_BRANDING = true;

const EMAIL_MYEDI_SUPPORT = "myEDIApplicationSupport@amerisourcebergen.com";

const URL_COMPANY = (!!USE_CENCORA_BRANDING)
    ? "https://www.cencora.com"
    : "https://www.amerisourcebergen.com";

const URL_ABOUT_US = (!!USE_CENCORA_BRANDING)
    ? "https://www.cencora.com"
    : "https://www.amerisourcebergen.com";

const URL_PRIVACY_STATEMENT = (!!USE_CENCORA_BRANDING)
    ? "https://www.amerisourcebergen.com/global-privacy-statement-overview"
    : "https://www.amerisourcebergen.com/global-privacy-statement-overview";

const URL_TERMS_CONDITIONS = (!!USE_CENCORA_BRANDING)
    ? "https://www.amerisourcebergen.com/global-terms-and-conditions"
    : "https://www.amerisourcebergen.com/global-terms-and-conditions";

const COMPANY_NAME = (USE_CENCORA_BRANDING)
    ? "Cencora"
    : "AmerisourceBergen";

const COPYRIGHT = `Copyright \u00AE ${new Date().getFullYear().toString()} ${COMPANY_NAME} Corporation. All rights reserved.`;

export {
    ENTERKEY, REMEMBERME, REGEX_EMAIL, REGEX_PHONE, checkUserTokenFormat,
    URL_COMPANY, URL_ABOUT_US, URL_PRIVACY_STATEMENT, URL_TERMS_CONDITIONS,
    EMAIL_MYEDI_SUPPORT, COPYRIGHT, COMPANY_NAME, USE_CENCORA_BRANDING
};