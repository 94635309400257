import { React, useState, useEffect } from 'react';
import { createHttpClient } from '../../utilities/httpclient';
import createUserSessionActivity from '../../utilities/usersessiontracking';
import { useNavigate } from 'react-router-dom';
import { AbdsButton, AbdsModal } from '@abds/react-bindings';

export default function DeleteExpirationEventPartnerConfirmationModal (props)
{
    let [visible, setVisible] = useState(!!props.showModal);
    let [expirationEventGUID, setExpirationEventGUID] = useState(props.expirationEventGUID);

    const navigate = useNavigate();

    const onClickShowDeleteConfirmationModal = e => {
        e.preventDefault();
        setVisible(true);
    };

    const onSubmitDeleteExpirationEvent = async () => {
        if (!visible)
            return;

        const httpclient = createHttpClient();
        const criteria = {
            'ExpirationEventGUID': expirationEventGUID,
            'ExpirationEventPartnerGUID': null,
            'Active': null
        };
        createUserSessionActivity(window.location.pathname, 'onSubmitDeleteExpirationEvent', criteria);
        await httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/expirationevent/deleteevent`, criteria)
            .then(response => {
                if (!!(response.data?.success))
                {
                    setVisible(false);
                    navigate('/main', { replace: true });
                }
            })
            .catch(e => setErrorMessage(e.response?.data || e.message));
    };

    const onCancelEvent = e => {
        e.preventDefault();
        setExpirationEventGUID(null);
        setVisible(false);
    };

    useEffect(() => {
        const modal = document.querySelector('abds-modal[name="confirmdeletemodal"]');
        modal?.setAttribute('open', !!visible);
    }, [visible]);

    return (
        <>
            { 
                !visible &&
                <AbdsButton icon-start="remove" palette="destructive" onClick={onClickShowDeleteConfirmationModal}>
                    Delete This Expiration Event
                </AbdsButton>
            }
            {
                visible &&
                (
                    <AbdsModal palette="danger" header-text="Expiration Event Delete Confirmation" name="confirmdeletemodal">
                        <div slot="modal-body">
                            <p>Are you <b>SURE</b> you want to delete this expiration event? This cannot be undone.</p>
                        </div>
                        <div slot="modal-footer">
                            <AbdsButton appearance="outline" onClick={onCancelEvent}>
                                Cancel
                            </AbdsButton>
                            &nbsp;
                            <AbdsButton icon-start="remove" palette="destructive" onClick={onSubmitDeleteExpirationEvent}>
                                DELETE
                            </AbdsButton>
                        </div>
                    </AbdsModal>
                )
            }
        </>
    );
}