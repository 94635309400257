import { React } from 'react';
import { Header } from './Header';
import { Home } from './Home';
import { Logout } from './Logout';
import { Footer } from './Footer';
import { useLocation } from 'react-router-dom';

import '../styles/fonts/font_abgilroy.css';
import "../styles/layout.css";
import '../styles/custom.css';
import '../styles/myedioverrides.css';

export function Layout (props)
{
    const location = useLocation();

    switch (location.pathname.toLowerCase())
    {
        case '/':
        case '/home':
            return (<div><Home /></div>);
        case '/logout':
            return (<div><Logout /></div>);
        default:
            return (
                <div className="contentflex">
                    <Header />
                    <div className="content">
                        {props.children}
                    </div>
                    <Footer />
                </div>
            );
    }
}