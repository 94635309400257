import { React, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createHttpClient } from '../utilities/httpclient';
import LocalStorageController from '../utilities/lsc';
import LoginHelp from './modals/LoginHelp';
import { NavItem } from 'reactstrap';
import { AbdsButton, AbdsInput, AbdsCheckbox } from '@abds/react-bindings';
import Base64 from 'crypto-js/enc-base64';
import SHA512 from 'crypto-js/sha512';
import { REMEMBERME, URL_ABOUT_US, URL_PRIVACY_STATEMENT, URL_TERMS_CONDITIONS, COPYRIGHT, COMPANY_NAME, USE_CENCORA_BRANDING } from '../utilities/constants';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from './displayareamessenger/DisplayAreaMessenger';

import { useKeyPressEnter } from '../customhooks/useKeyPressEnter';

import '../styles/home.css';

export function Home() {
    const topLeftLogoImageCssClass = (!!USE_CENCORA_BRANDING)
        ? "cencora"
        : "abc";

    const cLoginHelpRef = useRef(null);
    const rRememberMe = useRef(null);
    const rUsername = useRef(null);
    const rPassword = useRef(null);
    const rSubmitButton = useRef(null);

    let [usr, setUsr] = useState('');
    let [pwd, setPwd] = useState('');
    let [rememberMe, setRememberMe] = useState(false);
    let [messageContext, setMessageContext] = useState({});

    const navigate = useNavigate();

    const onUsrChange = e => setUsr(e.target.value);

    const onPwdChange = e => setPwd(e.target.value);

    const onChangeRememberMe = e => setRememberMe(!!e.target.checked);

    const getRememberMeState = () => {
        const lsc = new LocalStorageController();
        const state = lsc.getData(REMEMBERME);

        if (!state)
            return;

        const { checked, value } = state;
        setUsr(value || '');
        setRememberMe(checked || false);
    }

    const saveRememberMeState = () => {
        const lsc = new LocalStorageController();
        const data = {
            'checked': !!rememberMe,
            'value': usr
        };
        lsc.save(REMEMBERME, data);
    };

    const deleteRememberMeState = () => {
        const lsc = new LocalStorageController();
        lsc.remove(REMEMBERME);
    };

    const onLoginInfoClick = e => cLoginHelpRef.current.showLoginHelp(e);

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const onClickAttemptLogin = async e => {
        e.preventDefault();
        const promiseToSaveRememberMeState = async () => {
            return new Promise(resolve => {
                (rememberMe)
                    ? saveRememberMeState()
                    : deleteRememberMeState();
                resolve();
            });
        };

        await promiseToSaveRememberMeState().then(async () => await processLoginRequest());
    };

    const processLoginRequest = async () => {
        setMessageContext({});

        const data = {
            'Username': (usr || ''),
            'Password': (pwd || ''),
            'IpAddress': null,
            'UserAgent': navigator?.userAgent?.toString()
        };

        if (data.Username.length === 0 || data.Password.length === 0) {
            showErrorMessage('Username and password are both required');
            return;
        }

        data.Password = (SHA512(data.Password)).toString(Base64);

        const httpclient = createHttpClient();

        await httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/systemaccess/login`, data).then(response => {
            if (!response?.data) {
                showErrorMessage('There was a problem with the server.  Please try again later.');
                return;
            }
            const lsc = new LocalStorageController();
            lsc.save(process.env.REACT_APP_AUTH_TOKEN, response.data.toString());
            setPwd('');
            navigate('/main');
        })
            .catch(e => showErrorMessage(e.response?.data || e.message));
    };

    useKeyPressEnter(rSubmitButton);

    useEffect(() => {
        let target = document.querySelector('body');
        (target.classList.contains('login') || target.classList.add('login'));
        getRememberMeState();

        if (window.location.search.includes("error")) {
            let errorCode = -1;
            let message = '';

            if (window.location.search?.length > 0) {
                const getErrorCode = window.location.search.split('=');
                errorCode = getErrorCode[1] ?? -1;
                const lsc = new LocalStorageController();
                message = lsc.getData(errorCode);
            }

            if (message?.length > 0) {
                messageContext = createDisplayAreaMessengerContext();
                messageContext.error.message = message;
                setMessageContext(messageContext);
            }
        }

        return () => {
            let target = document.querySelector('body');
            target.classList.remove('login');
        };
    }, []);

    useEffect(() => {
        rUsername.current?.addEventListener('abdsInput', onUsrChange);
        rPassword.current?.addEventListener('abdsInput', onPwdChange);
        rRememberMe.current?.addEventListener('abdsChange', onChangeRememberMe);

        return () => {
            rUsername.current?.removeEventListener('abdsInput', onUsrChange);
            rPassword.current?.removeEventListener('abdsInput', onPwdChange);
            rRememberMe.current?.removeEventListener('abdsChange', onChangeRememberMe);
        };
    }, [rUsername, rPassword, rRememberMe]);

    return (
        <div className="container my-4">
            <div className="p-3 mb-4">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="p-2 bd-highlight col-example">
                        <div data-role="companylogo" className={topLeftLogoImageCssClass}>
                            <h4>{COMPANY_NAME}</h4>
                        </div>
                        <section id="logincontainer">
                            <DisplayAreaMessenger context={messageContext} />
                            <div data-role="login">
                                <h1>my<b>EDI</b></h1>

                                <hr />

                                <div>
                                    <h3>Log In</h3>
                                    <div data-role="formgroup">
                                        <div>

                                            <AbdsInput
                                                type="text"
                                                width="full"
                                                name="usr"
                                                required
                                                ref={rUsername}
                                                value={usr}
                                                tooltip="Enter your email address"
                                                placeholder="test@example.com">
                                                Username
                                            </AbdsInput>

                                        </div>
                                    </div>
                                    <div data-role="formgroup">
                                        <div>
                                            <AbdsCheckbox
                                                ref={rRememberMe}
                                                name="rememberme"
                                                checked={rememberMe}>
                                                Remember my User ID
                                            </AbdsCheckbox>
                                        </div>
                                    </div>
                                    <div data-role="formgroup">
                                        <div>

                                            <AbdsInput
                                                type="password"
                                                width="full"
                                                name="pwd"
                                                ref={rPassword}
                                                required
                                                value={pwd}
                                                placeholder="Enter password here">
                                                Password
                                            </AbdsInput>

                                        </div>
                                    </div>
                                    <div data-role="formgroup">
                                        <div>
                                            <span><a href="#" onClick={onLoginInfoClick}>Forgot my password</a></span>
                                            <LoginHelp ref={cLoginHelpRef}></LoginHelp>
                                        </div>
                                        <div className="right">
                                            <AbdsButton
                                                ref={rSubmitButton}
                                                onClick={onClickAttemptLogin}
                                                className="float-right">
                                                Log In
                                            </AbdsButton>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div data-role="loginfooter">
                                <ul className="navbar-nav flex-grow flex-row d-flex align-items-center justify-content-center">
                                    <NavItem>
                                        <a target="_blank" href={URL_ABOUT_US}>About Us</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                    </NavItem>
                                    <NavItem>
                                        <a target="_blank" href={URL_TERMS_CONDITIONS}>Terms and Conditions</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                    </NavItem>
                                    <NavItem>
                                        <a target="_blank" href={URL_PRIVACY_STATEMENT}>Privacy Statement</a>
                                    </NavItem>
                                </ul>
                                <p>{COPYRIGHT}</p>
                                <div className="centered">{process.env.REACT_APP_ENV_NAME}</div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}