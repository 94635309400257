import { React, useEffect } from 'react';
import { ENTERKEY } from '../utilities/constants';

export function useKeyPressEnter (refTargetButton, customFunction=null)
{
    const handleKeyPress = e => {
        if (![+e.keyCode, +e.which, +e.charCode].includes(+ENTERKEY))
            return;

        (typeof (customFunction) === 'function')
            ? customFunction(e)
            : refTargetButton.current.click(e);
    };

    useEffect(() => {
        window.addEventListener('keypress', handleKeyPress);

        return () => window.removeEventListener('keypress', handleKeyPress);
    }, []);
}