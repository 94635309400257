import { React, useState, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createHttpClient, handleRemoteError } from '../../utilities/httpclient';
import { AbdsButton, AbdsRadio, AbdsModal } from '@abds/react-bindings';

function handleSelectNewCertificate (state, action)
{
    switch (action.type)
    {
        case 'select':
            return {
                'enableSelect': !!action.selectedValue,
                'selectedValue': action.selectedValue
            };
        case 'cancel':
            return {
                'enableSelect': false,
                'selectedValue': null
            };
        default:
            return state;
    }
};

export default function NewCertificateLookupModal (props)
{
    //props will need at least a handle for capturing selected certificate to send back to parent component

    const communityID = +props.communityID;
    const currentCertificateID = +props.certificateID;

    const [visible, setVisible] = useState(false);
    const [localShowAddButton, setLocalShowAddButton] = useState(!!props.showAddButton);
    const [newCertificates, setNewCertificates] = useState(null);
    const [state, dispatch] = useReducer(handleSelectNewCertificate, { 'enableSelect': false, 'selectedValue' : null });

    const navigate = useNavigate();

    const populateNewCertificatesTable = async () => {
        const httpclient = createHttpClient();
        const criteria = {
            'CommunityID': communityID,
            'CurrentCertificateID': currentCertificateID
        };
        await httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/certificate/getnewcertificatesbycommunity`, criteria)
            .then(response => {
                setNewCertificates(response?.data || []);
            })
            .catch(e => {
                (handleRemoteError(navigate, e) || props.handleShowErrorMessage?.(e.response?.data || e.message))
            });
    };

    const onClickShowNewCertificateModal = e => {
        e.preventDefault();
        setVisible(true);
    };

    const onClickCloseNewCertificate = e => {
        e.preventDefault();
        dispatch({ type: 'cancel', selectedValue: null });
        setVisible(false);
    };

    const onClickLoadNewCertificate = e => {
        e.preventDefault();
        const datarecord = newCertificates.filter(newcert => +newcert.certificateID === +state.selectedValue.certificateID).shift();
        props.handleSelectedNewCertificate(datarecord);
        setVisible(false);
    };

    useEffect(() => {
        if (!!visible)
        {
            populateNewCertificatesTable();
            const modal = document.querySelector('abds-modal[name="newcertificatemodal"]');
            modal?.setAttribute('open', !modal.open);
            setLocalShowAddButton(false);
        }
        else
        {
            const modal = document.querySelector('abds-modal[name="newcertificatemodal"]');
            modal?.setAttribute('open', !modal.open);
            setLocalShowAddButton(true);
            setNewCertificates(null);
        }
    }, [visible]);

    return (
        <>
            {
                localShowAddButton &&
                (<AbdsButton onClick={onClickShowNewCertificateModal}>Add New Certificate</AbdsButton>)
            }
            {
                visible &&
                (
                    <AbdsModal header-text="Select New Certificate" name="newcertificatemodal">
                        <div slot="modal-body">
                            {
                                newCertificates === null &&
                                <h4>Loading new certificates...</h4>
                            }
                            {
                                newCertificates?.length === 0 &&
                                <h4>No new certificates</h4>
                            }
                            {
                                newCertificates?.length > 0 &&
                                <table className="absgtable">
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Certificate Name:</th>
                                            <th>Begin Date:</th>
                                            <th>End Date:</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            newCertificates.map((item, key) =>
                                            (
                                                <tr key={key+'_'+item.certificateID}>
                                                    <td className="centered">
                                                        <AbdsRadio
                                                            name="newcertificate"
                                                            value={item.certificateID}
                                                            onClick={() => dispatch({ type: 'select', selectedValue: item })}>
                                                        </AbdsRadio>
                                                    </td>
                                                    <td>{item.certificateName}</td>
                                                    <td>{item.beginDateDisplay?.split(' ')[0]}</td>
                                                    <td>{item.endDateDisplay?.split(' ')[0]}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                        <div slot="modal-footer">
                            <AbdsButton onClick={onClickCloseNewCertificate} appearance="outline" palette="brand">Cancel</AbdsButton>&nbsp;&nbsp;
                            <AbdsButton onClick={onClickLoadNewCertificate} disabled={!state.enableSelect} ref={props.modalSubmitButton}>Select</AbdsButton>
                        </div>
                    </AbdsModal>
                )
            }
        </>
    );
}