import { React, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createHttpClient, handleRemoteError } from '../utilities/httpclient';
import { AbdsButton, AbdsLabel, AbdsSelect, AbdsSelectOption, AbdsInput } from '@abds/react-bindings';
import CertificateTypeDisplayList from './auxiliary/CertificateTypeDisplayList';
import ExpirationEventMetadataTable from './auxiliary/ExpirationEventMetadataTable';
import NewCertificateLookupModal from './modals/NewCertificateLookupModal';
import NewExpirationEventEmailRequestModal from './modals/NewExpirationEventEmailRequestModal';
import DatePicker from 'react-datepicker';
import momentJS from 'moment'; //will need this to parse dates from database to populate DatePicker component
import createUserSessionActivity from '../utilities/usersessiontracking';
import { createDisplayAreaMessengerContext, DisplayAreaMessenger } from './displayareamessenger/DisplayAreaMessenger';
import DeleteExpirationEventPartnerConfirmationModal from './modals/DeleteExpirationEventConfirmationModal';

import { useKeyPressEnter } from '../customhooks/useKeyPressEnter';

import "react-datepicker/dist/react-datepicker.css";
import '../styles/absgtable.css';
import '../styles/certificatedetails.css';

export function CertificateManagementDetails (props)
{
    const emptyCertificateDetailState = {
        certificateID: 0,
        communityID: 0
    };
    let [expirationEventGUID, setExpirationEventGUID] = useState(null);
    let [communityID, setCommunityID] = useState(-1);
    let [certificateID, setCertificateID] = useState(-1);
    let [isInReadOnlyMode, setIsInReadOnlyMode] = useState(false);
    let [associatedExpirationEventMetadata, setAssociatedExpirationEventMetadata] = useState(null);
    let [communityName, setCommunityName] = useState('');
    let [defaultRoutingID, setDefaultRoutingID] = useState('');
    let [certificateActivationDateTime, setCertificateActivationDateTime] = useState(null);
    let [expiringCertificateDetails, setExpiringCertificateDetails] = useState(null);
    let [newCertificateDetails, setNewCertificateDetails] = useState(null);
    let [businessUnit, setBusinessUnit] = useState('');
    let [businessUnitOther, setBusinessUnitOther] = useState('');
    let [businessUnitList, setBusinessUnitList] = useState([]);
    let [notificationEmailDate, setNotificationEmailDate] = useState('');
    let [renewalCertificateDownloadEmailDate, setRenewalCertificateDownloadEmailDate] = useState('');
    let [expiringCertificateID, setExpiringCertificateID] = useState(null);
    let [renewCertificateID, setRenewCertificateID] = useState(null);
    let [messageContext, setMessageContext] = useState({});
    let [currentExpirationEventStatusKey, setCurrentExpirationEventStatusKey] = useState(-1);
    let [showModal, setShowModal] = useState(false);

    let rBusinessUnits = useRef(null);
    let rBusinessUnitOther = useRef(null);
    const rSubmitButton = useRef(null);
    const rSubmitButtonNewCertificateModal = useRef(null);
    const rSubmitButtonEmailRequestModal = useRef(null);

    const navigate = useNavigate();

    const params = useParams();

    const getBusinessUnits = async () => {
        const httpclient = createHttpClient();
        await httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/certificate/businessunits`).then(response => {
            setBusinessUnitList(response?.data || []);
        });
    };

    const showErrorMessage = msg => {
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        messageContext.error.message = msg;
        setMessageContext(messageContext);
    };

    const getCertificateDetailsByCommunity = async (communityKey, certificateKey, expirationEventGUID) => {
        const httpclient = createHttpClient();
        const criteria = {
            'CommunityID': +communityKey,
            'CertificateID': +certificateKey,
            'ExpirationEventGUID': expirationEventGUID
        };
        await httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/certificate/detailsbykey`, criteria).then(response => {
            setIsInReadOnlyMode(!!(response?.data?.isInReadOnlyMode ?? false));
            setAssociatedExpirationEventMetadata(response?.data?.associatedData);
            setExpirationEventGUID(response?.data?.associatedData?.expirationEventGUID);
            setCurrentExpirationEventStatusKey((response?.data?.associatedData?.statusKey) || -1);
            
            if (!!response?.data?.associatedData?.expirationEvent?.initialExpirationNotificationDateLocal)
            {
                const INITDate = momentJS(response.data.associatedData.expirationEvent.initialExpirationNotificationDateLocal).toDate();
                setNotificationEmailDate(INITDate);
            }

            if (!!response?.data?.associatedData?.expirationEvent?.renewalNotificationDateLocal)
            {
                const RNDate = momentJS(response.data.associatedData.expirationEvent.renewalNotificationDateLocal).toDate();
                setRenewalCertificateDownloadEmailDate(RNDate);
            }

            if (!!response?.data?.associatedData?.expirationEvent?.activationDateLocal)
            {
                let ACTDate = momentJS(response.data.associatedData.expirationEvent.activationDateLocal).toDate();
                setCertificateActivationDateTime(ACTDate);
            }

            const theBusinessUnitValue = +response?.data?.associatedData?.expirationEvent?.applicationTypeKey;
            const theBusinessUnitOtherText = response?.data?.associatedData?.expirationEvent?.businessUnit;
            setBusinessUnit(theBusinessUnitValue);
            setBusinessUnitOther(theBusinessUnitValue === 5
                ? theBusinessUnitOtherText
                : '');
            setCommunityID(+(response?.data?.communityID));
            setCommunityName(response?.data.communityName);
            setDefaultRoutingID(response?.data?.defaultRoutingID);
            setExpiringCertificateDetails(response?.data?.expired || emptyCertificateDetailState);
            setNewCertificateDetails(response?.data?.newcerts || emptyCertificateDetailState);
            setExpiringCertificateID(!!response?.data?.expired?.certificateID
                ? +response?.data?.expired?.certificateID
                : -1);
            setRenewCertificateID(!!response?.data?.newcerts?.certificateID
                ? +response.data.newcerts.certificateID
                : -1);
        })
        .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const onChangeBusinessUnit = e => setBusinessUnit(+e.target.value);

    const onChangeBusinessUnitOther = e => setBusinessUnitOther(e.target.value);

    const formatDateTimeWithoutTimeZone = value => new Date(value).toLocaleString();

    const onClickStartRenewalProcess = e => {
        e.preventDefault();
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        let validated = true;

        if (!newCertificateDetails || newCertificateDetails?.length === 0)
        {
            messageContext.error.details.push("A new certificate is required");
            validated = false;
        }

        if (!notificationEmailDate || notificationEmailDate?.length === 0)
        {
            messageContext.error.details.push("Notification email date cannot be empty");
            validated = false;
        }

        if (!renewalCertificateDownloadEmailDate || renewalCertificateDownloadEmailDate?.length === 0)
        {
            messageContext.error.details.push("Renewal certification download date cannot be empty");
            validated = false;
        }
        else if (momentJS(renewalCertificateDownloadEmailDate).isBefore(notificationEmailDate))
        {
            messageContext.error.details.push(`Renewal certification download date ${renewalCertificateDownloadEmailDate?.toLocaleString()} is an invalid date`);
            validated = false;
        }

        if (!certificateActivationDateTime || certificateActivationDateTime.length === 0)
        {
            messageContext.error.details.push("Certificate activation date cannot be empty");
            validated = false;
        }
        else if (momentJS(certificateActivationDateTime).isBefore(renewalCertificateDownloadEmailDate))
        {
            messageContext.error.details.push(`Certificate activation date ${certificateActivationDateTime?.toLocaleString()} is an invalid date`);
            validated = false;
        }

        if (!businessUnit || businessUnit?.length === 0)
        {
            messageContext.error.details.push("Business unit is required");
            validated = false;
        }
        else if (+businessUnit === 5 && (!businessUnitOther || businessUnitOther?.length === 0))
        {
            messageContext.error.details.push("Please enter your business unit if it is not provided in the drop-down list");
            validated = false;
        }

        if (messageContext.error.details.length > 0)
            messageContext.error.message = "Errors were found in processing:";

        if (!validated)
        {
            setMessageContext(messageContext);
            return;
        }

        const httpclient = createHttpClient();
        const criteria = {
            'ExpirationEventGUID': expirationEventGUID,
            'CommunityID': communityID,
            'NotificationEmailDate': notificationEmailDate,
            'ActivationDate': certificateActivationDateTime,
            'RenewalCertificateDownloadEmailDate': renewalCertificateDownloadEmailDate,
            'BusinessUnitID': businessUnit,
            'ExpiringCertificateID': expiringCertificateID,
            'RenewCertificateID': renewCertificateID,
            'BusinessUnitName': businessUnitOther,
            'RawActivationDate': formatDateTimeWithoutTimeZone(certificateActivationDateTime)
        };

        if (+criteria.BusinessUnitID !== 5)
            criteria.BusinessUnitName = ''; //remove name if user did not choose 'Other'

        createUserSessionActivity(window.location.pathname, 'onClickStartRenewalProcess', criteria);
        httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/certificate/startrenewal`, criteria).then(response => {
            if (!!response?.data?.success)
            {
                messageContext.success.message = response.data.message;
                setAssociatedExpirationEventMetadata(response.data.metadata);
                setIsInReadOnlyMode(true);

                if (!!response?.data?.metadata?.expirationEventGUID)
                    setExpirationEventGUID(response.data.metadata.expirationEventGUID);
            }
            else if (!!response?.data?.warning)
            {
                messageContext.warning.message = response.data.message;
            }
            else
            {
                messageContext.error.message = response.data.message;
            }
            window.scrollTo(0, 0);
            setMessageContext(messageContext);
        })
        .catch (e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const onClickDownloadPartnerActivity = e => {
        e.preventDefault();
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        const httpclient = createHttpClient();
        const criteria = {
            'CommunityID': communityID,
            'CertificateID': certificateID,
            'ExpirationEventGUID': expirationEventGUID
        };
        createUserSessionActivity(window.location.pathname, 'onClickDownloadPartnerActivity', criteria);
        httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/certificate/downloadpartnerlist`, criteria).then(response => {
            const isValidResponse = (r => {
                return !!r && !!r.data &&
                    r.data.hasOwnProperty('fileContents') &&
                    r.data.hasOwnProperty('contentType') &&
                    r.data.hasOwnProperty('fileDownloadName');
            })(response);

            if (!isValidResponse)
            {
                showErrorMessage('There was a problem fetching your requested export');
                return;
            }
            const hl = document.createElement('a');
            hl.href = `data:application/octet-stream;base64,${response.data.fileContents}`;
            hl.target = '_blank';
            hl.download = response.data.fileDownloadName;
            hl.click();
        })
        .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const onClickDownloadPartnerList = e => {
        e.preventDefault();
        setMessageContext({});
        messageContext = createDisplayAreaMessengerContext();
        const httpclient = createHttpClient();
        const criteria = {
            'CommunityID': communityID,
            'CertificateID': certificateID,
            'ExpirationEventGUID': expirationEventGUID
        };
        createUserSessionActivity(window.location.pathname, 'onClickDownloadPartnerList', criteria);
        httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/certificate/downloadpartnertokenlist`, criteria).then(response => {
            const isValidResponse = (r => {
                return !!r && !!r.data &&
                    r.data.hasOwnProperty('fileContents') &&
                    r.data.hasOwnProperty('contentType') &&
                    r.data.hasOwnProperty('fileDownloadName');
            })(response);

            if (!isValidResponse)
            {
                showErrorMessage('There was a problem fetching your requested export');
                return;
            }
            const hl = document.createElement('a');
            hl.href = `data:application/octet-stream;base64,${response.data.fileContents}`;
            hl.target = '_blank';
            hl.download = response.data.fileDownloadName;
            hl.click();
        })
        .catch(e => (handleRemoteError(navigate, e) || showErrorMessage(e.response?.data || e.message)));
    };

    const onClickRemoveNewCertificate = e => {
        e.preventDefault();
        setNewCertificateDetails(emptyCertificateDetailState);
    };

    const onClickGoToExpirationEventPartners = e => {
        e.preventDefault();

        if (!expirationEventGUID)
            return;

        navigate(`/expirationeventpartners/${expirationEventGUID}`);
        return;
    };

    const handleSelectedNewCertificate = value => {
        setRenewCertificateID(+(value?.certificateID || -1));
        value.remoteURLs = expiringCertificateDetails.remoteURLs;
        setNewCertificateDetails(value);
    };

    useKeyPressEnter(rSubmitButton, e => {
        if (!!document.querySelector('abds-modal[name="newcertificatemodal"]')?.open)
        {
            rSubmitButtonNewCertificateModal.current?.click(e);
        }
        else if (!!document.querySelector('abds-modal[name="emailmodal"]')?.open)
        {
            rSubmitButtonEmailRequestModal.current?.click(e);
        }
        else
        {
            rSubmitButton.current?.click(e);
        }
    });

    useEffect(() => {
        createUserSessionActivity(window.location.pathname);
        getBusinessUnits();
        const communityKey = isNaN(params.communityID) ? 0 : parseInt(params.communityID);
        const certificateKey = isNaN(params.certificateID) ? 0 : parseInt(params.certificateID);
        const expirationEventGUID = params.expirationEventGUID;
        setCertificateID(certificateKey);
        setCommunityID(communityKey);
        getCertificateDetailsByCommunity(communityKey, certificateKey, expirationEventGUID);
    }, []);

    useEffect(() => {
        rBusinessUnits.current?.addEventListener('abdsChange', onChangeBusinessUnit);
        rBusinessUnitOther.current?.addEventListener('abdsInput', onChangeBusinessUnitOther);

        return () => {
            rBusinessUnits.current?.removeEventListener('abdsChange', onChangeBusinessUnit);
            rBusinessUnitOther.current?.removeEventListener('abdsInput', onChangeBusinessUnitOther);
        };
    }, [rBusinessUnits, rBusinessUnitOther]);

    return (
        <div>
            <h3>Certificate Management: Expiration Event</h3>

            <hr className="abcblue" />

            <DisplayAreaMessenger context={messageContext} />

            <div data-role="detailheader">
                <div>
                    <div data-role="group">
                        <AbdsLabel>Community Name:</AbdsLabel>
                        <div>{communityName}</div>
                    </div>
                </div>
                <div>
                    <div data-role="group">
                        <AbdsLabel>AS2 ID:</AbdsLabel>
                        <div>{defaultRoutingID}</div>
                    </div>
                </div>
                <div>
                    {
                        isInReadOnlyMode &&
                        <div>
                            <div data-role="group">
                                <AbdsLabel>&nbsp;</AbdsLabel>
                                <div>
                                    <a href="#" onClick={onClickDownloadPartnerActivity} title="Download Partner Activity">
                                        <span className="bi bi-download"></span>&nbsp;
                                        Download Partner Activity
                                    </a>
                                </div>
                                <div>
                                    <a href="#" onClick={onClickDownloadPartnerList} title="Download Partner List">
                                        <span className="bi bi-download"></span>&nbsp;
                                        Download Partner List
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {
                isInReadOnlyMode && associatedExpirationEventMetadata != null &&
                <ExpirationEventMetadataTable {...associatedExpirationEventMetadata} />
            }
            {
                isInReadOnlyMode &&
                <>
                    <p>
                        <AbdsButton onClick={onClickGoToExpirationEventPartners}>
                            Modify Expiration Event Partners
                        </AbdsButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {
                            +currentExpirationEventStatusKey < 3 &&
                            <>
                                <DeleteExpirationEventPartnerConfirmationModal expirationEventGUID={expirationEventGUID} showModal={showModal} />
                            </>
                        }
                    </p>
                    <hr className="abcblue" />
                </>
            }

            <h4>Expiring Certificate Details</h4>
            {
                expiringCertificateDetails === null &&
                (<h3 className="center">Loading expiring certificate data...</h3>)
            }
            {
                expiringCertificateDetails != null &&
                +expiringCertificateDetails?.communityID === 0 &&
                +expiringCertificateDetails?.certificateID === 0 &&
                (<h3 className="center">No certificate data</h3>)
            }
            {
                expiringCertificateDetails != null &&
                +expiringCertificateDetails?.certificateID > 0 &&
                +expiringCertificateDetails?.communityID > 0 &&
                <>
                    <table className="absgtable auxiliary">
                        <thead>
                            <tr>
                                <th>Certificate Name:</th>
                                <th>Serial Number:</th>
                                <th>Begin Date:</th>
                                <th>End Date:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{expiringCertificateDetails.certificateName}</td>
                                <td>{expiringCertificateDetails.serialNumber}</td>
                                <td>{expiringCertificateDetails.beginDateDisplay}</td>
                                <td>{expiringCertificateDetails.endDateDisplay}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="absgtable auxiliary urltable">
                        <thead>
                            <tr>
                                <th>Type:</th>
                                <th colSpan="4">AS2 URL:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                +(expiringCertificateDetails?.remoteURLs?.length || 0) > 0 &&
                                expiringCertificateDetails.remoteURLs.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><CertificateTypeDisplayList items={item.certificateTypeDisplayItems} /></td>
                                            <td colSpan="3">{item.remoteURI}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </>
            }

            <hr className="abcblue" />

            <h4><span className="required">*</span>New Certificate Details</h4>
            {
                newCertificateDetails === null &&
                (<h3 className="center">Loading new certificate data...</h3>)
            }
            {
                newCertificateDetails != null &&
                +newCertificateDetails?.communityID === 0 &&
                +newCertificateDetails?.certificateID === 0 &&
                (<NewCertificateLookupModal
                    showAddButton={(!newCertificateDetails || (+newCertificateDetails.communityID > 0 && +newCertificateDetails.certificateID > 0))}
                    modalSubmitButton={rSubmitButtonNewCertificateModal}
                    communityID={communityID}
                    certificateID={certificateID}
                    handleShowErrorMessage={showErrorMessage}
                    handleSelectedNewCertificate={handleSelectedNewCertificate}
                />)
            }
            {
                newCertificateDetails != null &&
                +newCertificateDetails?.certificateID > 0 &&
                +newCertificateDetails?.communityID > 0 &&
                (<>
                    <table className="absgtable auxiliary">
                        <thead>
                            <tr>
                                <th>Certificate Name:</th>
                                <th>Serial Number:</th>
                                <th>Begin Date:</th>
                                <th>End Date:</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{newCertificateDetails.certificateName}</td>
                                <td>{newCertificateDetails.serialNumber}</td>
                                <td>{newCertificateDetails.beginDateDisplay}</td>
                                <td>{newCertificateDetails.endDateDisplay}</td>
                                <td className="centered">
                                    {
                                        (!isInReadOnlyMode || +currentExpirationEventStatusKey < 3) &&
                                        (
                                            <a
                                                data-commandargument={newCertificateDetails.certificateID}
                                                href="#"
                                                title="click to remove this certificate"
                                                onClick={onClickRemoveNewCertificate}>
                                                <i className="bi bi-trash"></i>
                                            </a>
                                        )
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="absgtable auxiliary urltable">
                        <thead>
                            <tr>
                                <th>Type:</th>
                                <th colSpan="4">AS2 URL:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                +(newCertificateDetails?.remoteURLs?.length || 0) > 0 &&
                                newCertificateDetails.remoteURLs.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><CertificateTypeDisplayList items={item.certificateTypeDisplayItems} /></td>
                                            <td colSpan="3">{item.remoteURI}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </>)
            }

            <hr className="abcblue" />

            <table className="renewalfunctions">
                <tbody>
                    <tr>
                        <td>
                            <div data-role="group">
                                <span className="required">*</span>
                                <AbdsLabel>Notification email date</AbdsLabel>
                                <div>
                                    <DatePicker
                                        selected={notificationEmailDate}
                                        disabled={isInReadOnlyMode && +currentExpirationEventStatusKey >= 3}
                                        onChange={date => setNotificationEmailDate(date)}
                                        showTimeSelect
                                        minDate={new Date()}
                                        timeIntervals={10}
                                        timeCaption="time"
                                        timeFormat="hh:mm aa"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        className="certificatedetails"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div data-role="group">
                                <span className="required">*</span>
                                <AbdsLabel>Renewal certificate download email date</AbdsLabel>
                                <div>
                                    <DatePicker
                                        selected={renewalCertificateDownloadEmailDate}
                                        onChange={date => setRenewalCertificateDownloadEmailDate(date)}
                                        minDate={new Date()}
                                        disabled={isInReadOnlyMode && +currentExpirationEventStatusKey >= 4}
                                        showTimeSelect
                                        timeIntervals={10}
                                        timeCaption="time"
                                        timeFormat="hh:mm aa"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        className="certificatedetails"
                                    />
                                </div>
                            </div>
                        </td>
                        {
                            !!expirationEventGUID &&
                            <td rowSpan="3" className="expirationemailcell">
                                <div data-role="group">
                                    <NewExpirationEventEmailRequestModal
                                        modalSubmitButton={rSubmitButtonEmailRequestModal}
                                        expirationEventGUID={expirationEventGUID}
                                    />
                                </div>
                            </td>
                        }
                    </tr>
                    <tr>
                        <td>
                            <div data-role="group">
                                <span className="required">*</span>
                                <AbdsLabel>Certificate activation date and time</AbdsLabel>
                                <div>
                                    <DatePicker
                                        selected={certificateActivationDateTime}
                                        onChange={date => setCertificateActivationDateTime(date)}
                                        disabled={isInReadOnlyMode && +currentExpirationEventStatusKey >= 5}
                                        minDate={new Date()}
                                        showTimeSelect
                                        timeIntervals={10}
                                        timeCaption="time"
                                        timeFormat="hh:mm aa"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        className="certificatedetails"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div data-role="group">
                                <span className="required">*</span>
                                <AbdsLabel>Business Unit</AbdsLabel>
                                <div>
                                    <div className="certificatedetails">
                                        <AbdsSelect
                                            name="businessunits"
                                            ref={rBusinessUnits}
                                            value={businessUnit}
                                            width="full"
                                            placeholder="-- Choose Business Unit --">
                                            {
                                                businessUnitList?.map(item => {
                                                    return (
                                                        <AbdsSelectOption
                                                            key={item.applicationTypeKey}
                                                            value={item.applicationTypeKey}
                                                            selected={+businessUnit === +item.applicationTypeKey}>
                                                            {item.label}
                                                        </AbdsSelectOption>
                                                    );
                                                })
                                            }
                                        </AbdsSelect>
                                    </div>
                                    <div>
                                        <div className="certificatedetails">
                                            <AbdsInput
                                                style={{ 'display': +businessUnit === 5 ? 'block' : 'none' }}
                                                type="text"
                                                width="full"
                                                name="otherBusinessUnit"
                                                ref={rBusinessUnitOther}
                                                value={businessUnitOther}
                                                tooltip="Enter Business Unit Name"
                                                placeholder="Enter Business Unit Name">
                                                Enter Business Unit Name
                                            </AbdsInput>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <AbdsButton onClick={onClickStartRenewalProcess} ref={rSubmitButton}>
                                    {
                                        !isInReadOnlyMode &&
                                        <>Start Renewal Process</>
                                    }
                                    {
                                        isInReadOnlyMode &&
                                        <>Update Renewal Process</>
                                    }
                                </AbdsButton>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}