import { createHttpClient } from '../utilities/httpclient';

export default function createUserSessionActivity (url, action=null, actionData=null)
{
    const data = {
        'Url': url,
        'Action': action,
        'ActionData': (!!actionData ? JSON.stringify(actionData) : null)
    };
    const httpclient = createHttpClient();
    httpclient.post(`${process.env.REACT_APP_API_ENDPOINT}/systemaccess/trackusersessionactivity`, data).then(response => response).catch(e => e);
}